<script>
    import Vue from 'vue';
    import BaseVue from '@frontend/Base.vue';
    import Gen from '../helper/Gen';
    Vue.component("Header",()=>import("@frontend/part/Header.vue"))

    export default {
        extends: BaseVue,
        data() {
            return {
            	staticContent:{},
            	data:{data:false},
            	loadingLoadmore: false,
            	popular:[],
            	tags:[],
            	heroImage:{},
            	filter: {skip:0,years:'all',month:''},
            	year_dm:[],
                range_months:[],
            }
        },
        computed:{
			myParams(){
				var query = Gen.clone(this.$route.query)
				if(this.$route.query.tag) query.tag = this.$route.query.tag
				return query
			}
		},
        async mounted() {
        	await this.ready();

            this.$set(this.$root, 'page', this);
            Vue.component("Header",()=>import("@frontend/part/Header.vue"))
            if (typeof SEMICOLON != "undefined") SEMICOLON.slider.init()
            setTimeout(() => {
                SEMICOLON.documentOnReady.init()
            }, 100)
            $(document).ready(() => {
                AOS.init()
            })
            this.apiGetJurnalList()
            this.apiGetTags()
            this.apiGetHero()
        },
        methods: {
        	apiGetJurnalList(callBack=null){
        		this.loadingOverlay = false
        		Gen.apirest('/article', this.myParams, (err, resp)=>{
        			this.loadingOverlay = false
	              	if(err) console.log(err)
	              	if(callBack) return callBack(resp)
	              	this.data=resp.data
	              	this.popular=resp.popular
	              	this.year_dm=resp.year_dm
                    this.range_months=resp.range_months
	              	this.$root.topProgress.done()

                    // Autoselect
                    if(resp.select_year){
                        this.filter.years = resp.select_year
                        this.filter.month = resp.select_month
                    }
                    Gen.apirest("/article-get-month", {years:this.filter.years}, (err,resp)=>{
                        this.range_months = resp
                    }, "GET");
	          	})
        	},
        	apiGetTags(){
                Gen.apirest('/tags', {}, (err, resp)=>{
                    if(err) console.log(err)
                    this.tags=resp.data
                })
            },
            apiGetHero(){
                Gen.apirest('/hero/9', {}, (err, resp)=>{
                    if(err) console.log(err)
                    this.heroImage=resp.data
                })
            },
            onPaging(page = 1) {
		      	if ($(".page-item.active").text() == page) return;
		      	var query = Object.assign(Gen.clone(this.$route.query), {
		        	page: page
		      	})
		      	this.$router.push({
		        	name: this.$route.name,
		        	query: query
		      	})
		    },
		    monthFilter(v){
                if(!v) return;
                this.filter.month = v
            }
        },
        watch:{
        	$route() {
        		this.apiGetJurnalList()
        		setTimeout(()=>{ $("html,body").scrollTop(320) }, 100)
        	},
        	'filter.years'(v){
                Gen.apirest("/article-get-month", {years:v}, (err,resp)=>{
                    this.range_months = resp
                }, "GET");

                this.$router.push({name:'Artikel',query:{years:v}})
            }
        },
    };
</script>
<template>
  	<div Content>
  		<Header></Header>
		<section id="slider" class="activities slider-element slider-parallax swiper_wrapper clearfix sliderActivities">
            <!-- slider-parallax-inner -->
            <div class="paralaxActivities">
                <div class="swiper-container swiper-parent swiper-container-activities">
                    <div v-if="!isMobile" class="swiper-wrapper" :style="'background-image: url(\''+uploader(heroImage.mhi_image, '1349x350')+'\'); background-size: cover; background-position: top;'">
                        <div class="container clearfix">
                            <div class="slider-caption slider-caption-activities dark go-mid">
                                <h3>{{heroImage.mhi_title}}</h3>
                                <p class="mt-2">{{heroImage.mhi_desc}}</p>
                            </div>
                        </div>
                        <div class="video-overlay" style="background-color: rgba(0,0,0,0.4);"></div>
                    </div>
                    <div v-else class="swiper-wrapper bg-x-33" :style="'background-image: url(\''+uploader(heroImage.mhi_image,'360x240')+'\'); background-size: cover; background-position: top;'">
                        <div class="container clearfix">
                            <div class="slider-caption slider-caption-activities dark go-mid">
                                <h3>{{heroImage.mhi_title}}</h3>
                                <p class="mt-2">{{heroImage.mhi_desc}}</p>
                            </div>
                        </div>
                        <div class="video-overlay" style="background-color: rgba(0,0,0,0.4);"></div>
                    </div>
                </div>
                <a href="javascript:;" data-scrollto="#content" data-offset="100" class="dark one-page-arrow"><i class="icon-angle-down infinite animated fadeInDown"></i></a>
            </div>
        </section>
		<section id="page-title" class="page-title-mini">
			<div class="container clearfix">
				<h1 class="d-none d-md-block">&nbsp;</h1>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link :to="{name:'index'}">{{ web.mn_home }}</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">{{ web.mn_artikel }}</li>
				</ol>
			</div>
		</section>
     	<section id="content" class="overflow-none">
			<div class="content-wrap">
				<section class="section nobottommargin notopmargin">
					<div class="container">

                        <!-- Filter Mobile -->
                        <div class="row justify-content-center d-md-none mb-3">
                            <div class="col-lg-4 side-art">
                                <div class="wrap_filter_achievment" style="width:100%;">
                                    <VForm method="get">
                                        <h3>Filter</h3>
                                        <div class="form-row mb-4 justify-content-end">
                                            <div class="col-md-12">
                                                <select v-model="filter.years" name="years" class="form-control">
                                                    <option :value="'all'">Semua Tahun</option>
                                                    <option v-for="(v1,k1) in year_dm" :value="v1.ppr_year" v-html="v1.ppr_year"></option>
                                                </select>
                                            </div>
                                            <div class="col-md-12" v-if="filter.years!='all'">
                                                <ul>
                                                    <li><router-link :class="filter.month == 'all' ? 'active_filter' : '' "   @click="monthFilter('all')" :to="{name:'Artikel',query:{years:filter.years}}">Semua Bulan <i class="icon-angle-right"></i></router-link></li>
                                                    
                                                    <li v-for="(v2,k2) in range_months"  @click="monthFilter(v2.key)">
                                                        <router-link :class="v2.key == filter.month ? 'active_filter' : '' " :to="{name:'Artikel',query:{years:filter.years,month:v2.key}}">{{v2['value']}} <i class="icon-angle-right"></i></router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </VForm>
                                </div>
                            </div>
                        </div>

						<div class="row justify-content-center">
							<div class="col-lg-8">
								<article v-if="data.data.length" class="list_article" v-for="(v,k) in data.data">
									<div class="row">
										<div class="col-lg-5">
											<div class="list_img">
												<VImg v-if="!isMobile" :src="uploader(v.ap_photo,'295x196')" :alt="v.ap_title" :title="v.ap_title"></VImg>
												<VImg v-else :src="uploader(v.ap_photo,'330x219')" :alt="v.ap_title" :title="v.ap_title"></VImg>
											</div>
										</div>
										<div class="col-lg-7">
											<div class="article_content">
												<h3><router-link :to="{name:'ArticleDetail',params:{slug:v.ap_slug}}">{{ v.ap_title }}</router-link></h3>
												<p>{{v.desc}}</p>
											</div>
											<div class="wrap_author">
												<ul>
													<li><a href="javascript:;"><i class="icon-calendar21"></i> {{(v.ap_publish_date).dates('format')}}</a>
													</li>
													<li><a href="javascript:;"><i class="icon-user21"></i> {{v.ap_author_name}}</a></li>
												</ul>
											</div>
										</div>
									</div>
								</article>
                                <div v-if="!data.data.length" class="col-sm-12 col-lg-12 col-md-12">
                                    <h3 class="text-center">Tidak ada data</h3>
                                </div>
								<Pagination v-if="data.length"  :data="data" :limit="9" @pagination-change-page="onPaging"></Pagination>
                				<LoadingSpinner v-if="data===false" light></LoadingSpinner>
							</div>
							<div class="col-lg-4 side-art">
								<div class="wrap_filter_achievment d-none d-md-block" style="width:100%;">
                                    <VForm method="get">
                                        <h3>Filter</h3>
                                        <div class="form-row mb-4 justify-content-end">
                                            <div class="col-md-12">
                                                <select v-model="filter.years" name="years" class="form-control">
                                                    <option :value="'all'">Semua Tahun</option>
                                                    <option v-for="(v1,k1) in year_dm" :value="v1.ppr_year" v-html="v1.ppr_year"></option>
                                                </select>
                                            </div>
                                            <div class="col-md-12" v-if="filter.years!='all'">
                                                <ul>
                                                    <li><router-link :class="filter.month == 'all' ? 'active_filter' : '' "   @click="monthFilter('all')" :to="{name:'Artikel',query:{years:filter.years}}">Semua Bulan <i class="icon-angle-right"></i></router-link></li>
                                                    
                                                    <li v-for="(v2,k2) in range_months"  @click="monthFilter(v2.key)">
                                                        <router-link :class="v2.key == filter.month ? 'active_filter' : '' " :to="{name:'Artikel',query:{years:filter.years,month:v2.key}}">{{v2['value']}} <i class="icon-angle-right"></i></router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </VForm>
                                </div>
								<div class="wrap_side_article" style="width:100%;margin-top:30px;">
									<h3>{{ web.lbl_artikel_terpopuler }}</h3>
									<div v-for="(p,k) in popular" class="wrap_article_side">
										<div class="row">
											<div class="col-md-4 col-5 pr-md-0 pr-lg-3">
												<div class="pict_blog_side">
													<VImg class="img-responsive" :src="uploader(p.ap_photo, '137x90')" :alt="p.ap_title" :title="p.ap_title"></VImg>
												</div>
											</div>
											<div class="col-md-8 col-7">
												<div class="wrap_article_side_content">
													<h3>
														<router-link :to="{name:'ArticleDetail',params:{slug:p.ap_slug}}">
															{{ p.ap_title }}
														</router-link>
													</h3>
													<div class="wrap_author">
														<ul>
															<li><a href="javascript:;"><i class="icon-calendar21"></i>&nbsp;{{(p.ap_publish_date).dates('format')}}
																</a></li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="wrap_side_article" style="width:100%;">
									<h3>{{ web.lbl_tags }}</h3>
									<div class="tagcloud">
										<router-link v-for="(v2,k2) in tags" :to="{name:'DetailTags',params:{slug:v2.ppt_tag_slug}}">
											{{ v2.ppt_tag_name }}
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</section>
  	</div>
</template>